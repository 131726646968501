<script lang="ts" setup>
const props = defineProps<{
  __typename?: string;
}>();

/**
 * reslove dynamic component
 * prepare component name
 * remove "Component" and "Block" from __typename
 * split by capital letters concat with -
 */
const component = computed(() => {
  const componentName = props.__typename?.replace(/^Component/, "") ?? "";
  return resolveComponent(componentName);
});
</script>
<template>
  <component :is="component" v-if="component" v-bind="$attrs" />
</template>
